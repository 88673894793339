<template>
  <a-card class="card rent" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getFmsMetaPage"
      :deleteFun="deleteFmsMetaInfo"
      :createFun="createFmsMetaInfo"
      :updateFun="updateFmsMetaInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getFmsMetaPage, createFmsMetaInfo, updateFmsMetaInfo, deleteFmsMetaInfo } from '@/api/fms'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        { label: '交易类型', name: 'business_name', fieldType: '', queryType: '%' }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '交易类型',
          dataIndex: 'business_name'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        business_name: [
          { required: true, message: '请填写交易类型', trigger: 'blur' }
        ]
      },
      modelTitle: '交易类型管理',
      formFields: [
        {
          label: '交易类型', name: 'business_name', type: 'default'
        }
      ],
      moduleName: 'fms_business_type'
    }
  },
  methods: {
    getFmsMetaPage,
    createFmsMetaInfo,
    updateFmsMetaInfo,
    deleteFmsMetaInfo
  }
}
</script>
